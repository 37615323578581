import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import lenkaImg from "../images/lenka-piano-teacher-small.jpg"

const experience = [
  {
    title: "Self Employed Private Studio",
    location: "Mount Laurel, NJ",
    description: "Since 2006, Piano Teacher",
  },
  {
    title: "Perkins Center for the Arts",
    location: "Moorestown, NJ",
    description: "2005 - 2014, Piano Teacher",
  },
  {
    title: "Moorestown School of Music",
    location: "Moorestown, NJ",
    description: "2003 - 2006, Piano Teacher and Accompanist",
  },
  {
    title: "Theatre Jonas Zaborsky",
    location: "Presov, Slovakia",
    description: "1997 - 2001, Accompanist",
  },
  {
    title: "Self Employed Private Studio",
    location: "Presov, Slovakia",
    description: "1997 - 2001, Piano Teacher",
  },
  {
    title: "Pavol Josef Safarik University",
    location: "Presov, Slovakia",
    description: "1989 - 1993, Piano Instructor and Accompanist",
  },
  {
    title: "The Art School of M.Moyzes",
    location: "Presov, Slovakia",
    description: "1988 - 2001; Piano Teacher and Accompanist",
  },
]

const education = [
  {
    title: "Continuing Education",
    description:
      "from internationaly recognized concert pianist, composer, and former profesor at Moscow Conservatory Vyacheslav Gryaznov.",
  },
  {
    title: "Private Lessons from Tamara Syrtseva",
    location: "Cherry Hill, NJ",
    description:
      "Private lessons and advanced tutoring from Tamara Syrtseva, an internationally recognized concert pianist and Professor of Music from the Moscow Conservatory, former student of Grigory Ginsburg and Emil Gilels.",
  },
  {
    title: "Teacher Training - Temple University",
    location: "Philadelphia, PA",
    description:
      "Temple University; Philadelphia, PA- Registered Teacher Training for Suzuki with Dr. Ray Landers and Joan Krzywicki.",
  },
  {
    title: "Post Graduate Studies - Academy of Fine Arts",
    location: "Bratislava, Slovakia",
    description:
      "Post Graduate Studies in Piano Performance with Professor Ida Cernecka; former student of Vera Gornostajevova and Rudolf Macudzinsky.",
  },
  {
    title: "State Conservatoire in Slovakia",
    location: "Kosice, Slovakia",
    description:
      "Bachelor of Music Degree in Piano Performance from State Conservatoire in Kosice, Slovakia with profesor Mgr. Ivan Miller.",
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="About me" />

    <section className="pb-10">
      <h1 className="text-3xl lg:text-4xl text-center pb-6">About me</h1>
      <div className="flex justify-center items-center pb-6">
        <img
          src={lenkaImg}
          alt="Lenka Lazorikova - Piano Instructor"
          className="p-1 bg-white rounded border border-gray-light w-48"
        />
      </div>
      <p className="max-w-3xl mx-auto text-justify leading-loose">
        Over twenty years of professional experience in the field of teaching,
        performing and accompanying. A Nationally Certified Teacher of Music and
        on the Board of Judges for the National Piano Guild of Piano Teachers.
        Certified Suzuki Teacher. Member of New Jersey Music Teachers
        Association, South Jersey Music Teachers Association, National Guild of
        Piano Teachers, Music Teachers National Association and Suzuki
        Association of the Americas. South Jersey Music Teachers association
        Historian 2007 to 2008. Many of my students participate in numerous
        local, state and national auditions, competitions, concerts,
        conferences, festivals, recitals and several of them pursued music
        careers.
      </p>
    </section>

    <section className="pb-10">
      <h2 className="text-2xl lg:text-3xl text-center pb-4">Experience</h2>
      <ul className="w-full max-w-xl mx-auto bg-yellow-light-a30 border border-b-none border-gray-light rounded">
        {experience.map((item, index) => (
          <li
            key={`experience-item-${index}`}
            className="border-b border-gray-light p-4"
          >
            <h3 className="text-red text-lg">{item.title}</h3>
            <div className="text-sm text-gray-lighter pb-1">
              {item.location}
            </div>
            <p>{item.description}</p>
          </li>
        ))}
      </ul>
    </section>

    <section>
      <h2 className="text-2xl lg:text-3xl text-center pb-4">Education</h2>
      <ul className="w-full max-w-3xl mx-auto bg-yellow-light-a30 border border-b-none border-gray-light rounded">
        {education.map((item, index) => (
          <li
            key={`experience-item-${index}`}
            className="border-b border-gray-light p-4"
          >
            <h3 className="text-red text-lg">{item.title}</h3>
            <div className="text-sm text-gray-lighter pb-1">
              {item.location}
            </div>
            <p className="text-justify">{item.description}</p>
          </li>
        ))}
      </ul>
    </section>
  </Layout>
)

export default IndexPage
